import { setupSGWTConnect, SGWTConnectCore } from '@sgwt/connect-core';
import { Environment, getEnvironment, ISGConnectConfiguration } from 'core/constants';

let sgwtConnect: SGWTConnectCore;

declare global {
    interface Window {
        sgwtConnect: SGWTConnectCore;
    }
}

export const loadSGConnect = (configuration: ISGConnectConfiguration) => {
    initSGWTWidgetConfiguration();

    if (!sgwtConnect) {
        sgwtConnect = setupSGWTConnect({
            authorization_endpoint: configuration.authorizationEndpoint,
            client_id: configuration.clientId,
            scope: configuration.scope,
            acr_values: configuration.level,
        });
    }

    setupPassiveMode();

    if (!sgwtConnect.isAuthorized()) {
        sgwtConnect.requestAuthorization();
        return;
    }
    const windowIfDefined =
        typeof window === 'undefined'
            ? null
            : ((window as unknown) as Window);
    if (windowIfDefined && !windowIfDefined.sgwtConnect) {
        windowIfDefined.sgwtConnect = sgwtConnect;
    }

    return sgwtConnect;
};

export const initSGWTWidgetConfiguration = () => {
    const env = getEnvironment();
    if (window.SGWTWidgetConfiguration) {
        console.error('SGWTWidgetConfiguration is already configured and environment can\'t be updated because it was already used');
    } else {
        window.SGWTWidgetConfiguration = {
            environment: env === Environment.Production ? 'production' : 'homologation',
        };
    }
};

export const setupPassiveMode = () => {
    const widget = document.querySelector('sgwt-connect') as any;
    if (!widget) {
        return;
    }

    if (typeof widget.setSgwtConnectInstance === 'undefined') {
        // retry until widget is loaded
        setTimeout(setupPassiveMode, 100);
        return;
    }

    widget.setSgwtConnectInstance(sgwtConnect);
};

export const getSGConnect = () => {
    return sgwtConnect;
};
