import { AccountCenter } from './AccountCenter';
import React from 'react';
import { Link } from 'react-router-dom';

export const Header: React.FC = () => {
    return <nav className="navbar">
        <div className="navbar-title align-items-center">
            <Link
                to={'/'}
                className="navbar-title-link">

                <div className="navbar-logo">
                    <img src="https://shared.sgmarkets.com/assets/images/logo/socgen_logo.svg" alt="SG logo Glyph" />
                </div>

                <div className="navbar-title-divider" />
                <div className="navbar-service-name">
                    Content Management
                </div>
            </Link >
            <div className="d-md-none">
                <button id="mini-menu" className="btn btn-flat">
                    <i className="icon">menu</i>
                </button>
            </div>
        </div>

        <div className="navbar-content">
            <div className="d-flex flex-grow-1 mx-3 align-items-center justify-content-end">
                <AccountCenter />
            </div>
        </div>

    </nav>;
};
