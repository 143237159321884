export enum Environment {
    Local = 'Local',
    Development = 'Development',
    Homologation = 'Homologation',
    Production = 'Production',
    Test = 'Test',
}

export const getEnvironment = () => {
    let environment = Environment.Production;
    if (typeof window === 'undefined' || !window.location.hostname) {
        environment = Environment.Test;
    } else if (window.location.hostname === 'local.fr.world.socgen'
        || window.location.hostname === 'localhost') {
        environment = Environment.Local;
    } else if (window.location.hostname === 't-content-ui-dev.sgmarkets.com'
        || window.location.hostname === 'contentmanager-dev.sgmarkets.com') {
        environment = Environment.Development;
    } else if (window.location.hostname === 't-content-ui-uat.sgmarkets.com'
        || window.location.hostname === 'contentmanager-uat.sgmarkets.com') {
        environment = Environment.Homologation;
    } else if (window.location.hostname === 't-content-ui.sgmarkets.com'
        || window.location.hostname === 't-content-ui-preview.sgmarkets.com'
        || window.location.hostname === 'contentmanager.sgmarkets.com'
        || window.location.hostname === 'contentmanager-preview.sgmarkets.com') {
        environment = Environment.Production;
    }
    return environment;
};

export interface ISGConnectConfiguration {
    authorizationEndpoint: string;
    unauthorizationEndpoint: string;
    clientId: string;
    scope: string;
    level: 'L1' | 'L2' | 'L3';
}

export interface IConfiguration {
    sgConnect: ISGConnectConfiguration;
    apiContentUrl: string;
    apiContentRequestSizeLimitInMB: number;
    serveContentUrl: string;
    kibanaUrl: string;
    widgetCdnBaseUrl: string;
    sharedSgMarketsBaseUrl: string;
    sgMonitoringRumUrl: string;
}

const defaultConfiguration: IConfiguration = {
    sgConnect: {
        authorizationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
        unauthorizationEndpoint: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://pretrade-api-content-uat.fr.world.socgen',
        clientId: '44e9dd34-ef1a-4259-b49f-ee31ff037910',
        scope: 'openid profile mail api.insightuserdomain.v1 api.manage-contacts.v1 api.researchcontentdomain.admin api.researchcontentdomain.user api.researchcontentdomain.v1',
        level: 'L1',
    },
    apiContentUrl: 'https://sgmarkets-api-content-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
    apiContentRequestSizeLimitInMB: 28,
    serveContentUrl: 'https://content-dev.sgmarkets.com',
    kibanaUrl: 'https://3364045e098b447682837a9c4841b039.ece.sgmonitoring.dev.euw.gbis.sg-azure.com',
    widgetCdnBaseUrl: 'https://sgwt-widgets-dev.sgmarkets.com',
    sharedSgMarketsBaseUrl: 'https://shared-uat.sgmarkets.com',
    sgMonitoringRumUrl: 'https://insight-realm-apm-hom.fleet.uat.sgmonitoring.dev.euw.gbis.sg-azure.com',
};

export const configurationPerEnvironment: Record<Environment, IConfiguration> = {
    [Environment.Local]: {
        ...defaultConfiguration,
    },
    [Environment.Development]: {
        ...defaultConfiguration,
        apiContentUrl: 'https://sgmarkets-api-content-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
        serveContentUrl: 'https://content-dev.sgmarkets.com',
    },
    [Environment.Homologation]: {
        ...defaultConfiguration,
        apiContentUrl: 'https://sgmarkets-api-content-uat.sgmpinsight.dev.euw.gbis.sg-azure.com',
        serveContentUrl: 'https://content-uat.sgmarkets.com',
    },
    [Environment.Production]: {
        ...defaultConfiguration,
        sgConnect: {
            ...defaultConfiguration.sgConnect,
            authorizationEndpoint: 'https://sso.sgmarkets.com/sgconnect',
            unauthorizationEndpoint: 'https://shared.sgmarkets.com/unauthorized/?redirectUri=https://content.sgmarkets.com',
            clientId: 'c1e70201-db6b-4390-9233-b6ee5d5d27da',
        },
        apiContentUrl: 'https://sgmarkets-api-content-prd.sgmpinsight.prd.euw.gbis.sg-azure.com',
        serveContentUrl: 'https://content.sgmarkets.com',
        kibanaUrl: 'https://97c8aba440514e86bee255d620fd4453.ece.sgmonitoring.prd.euw.gbis.sg-azure.com',
        widgetCdnBaseUrl: 'https://sgwt-cdn-widgets.sgmarkets.com',
        sharedSgMarketsBaseUrl: 'https://shared.sgmarkets.com',
        sgMonitoringRumUrl: 'https://t-monitoring-rum.sgmarkets.com/rum/540a239031b64d7fbe34fe54fef041db',
    },
    [Environment.Test]: {
        ...defaultConfiguration,
    },
};

export const configuration = configurationPerEnvironment[getEnvironment()];
